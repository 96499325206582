import $ from 'jquery'
import '../snap-engage.min'

(function() {
  SnapEngage.setButtonEffect('-4px')
  $('.SnapABug_Button').addClass('d-sm-none')

  const chat = document.getElementById('js--snapengage-chat')
  if (!chat) {
    return
  }

  chat.addEventListener('click', (e) => {
    e.preventDefault()
    e.stopPropagation()

    SnapABug.setWidgetId(snapengageChatWidgetId)
    return SnapEngage.openProactiveChat(true, true)
  })

  const email = document.getElementById('js--snapengage-email')
  email.addEventListener('click', (e) => {
    e.preventDefault()
    e.stopPropagation()

    SnapABug.setWidgetId(snapengageEmailWidgetId)
    return SnapABug.startLink()
  })
}())
